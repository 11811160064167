<template>
  <div class="o-cart-wishlist">
    <div class="o-cart-wishlist-header">
      <div
        class="o-cart-wishlist-header__group"
        v-for="wishlist in wishlists"
        :key="wishlist.wishlist_id"
        :class="{'o-cart-wishlist-header__group--active': isWishlistActive(wishlist.wishlist_id)}"
        data-transaction-name="Set Active Wishlist"
        @click="setActiveWishlist(wishlist.wishlist_id)"
      >
        {{ wishlist.name }} ({{ wishlist.items.length }})
      </div>
    </div>
    <div class="o-cart-wishlist-content">
      <div
        :class="[
          'o-cart-wishlist-content__navbar',
          {'o-cart-wishlist-content__navbar--is-cart': isCartType},
          {'o-cart-wishlist-content__navbar--empty': !products.length}
        ]"
      >
        <div class="o-cart-wishlist-content__navbar-block">
          <SfButton
            v-show="products.length"
            class="o-cart-wishlist-content__button-items-to-cart sf-button--primary"
            data-transaction-name="Add All Products To Cart"
            @click="addAllProductsToCart"
          >
            {{ $t('Add all products in stock to cart') }}
          </SfButton>
        </div>
        <div
          v-if="!isCartType"
          class="o-cart-wishlist-content__navbar-block o-cart-wishlist-content__navbar-block--edit"
        >
          <div
            class="action-edit-group"
            v-show="!isMobile && isShowEditAction && !isWishlistActive(defaultWishlistId)"
            data-transaction-name="Edit Wishlist Group"
            @click="editWishlistModal"
          >
            <span class="action-edit-group__icon">
              <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 4 1 14v3h3L14 7m-3-3 3-3 3 3-3 3m-3-3 3 3" stroke="#EB6747" />
              </svg>
            </span>
            <span class="action-edit-group__title">
              {{ $t('Edit list') }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="products.length"
        class="o-cart-wishlist-content__product-list"
      >
        <MCartWishlistProductTile
          v-for="(product) in products"
          :product="product"
          :key="product.id"
          :title="product.title"
          :image="product.image"
          :regular-price="product.price.regular"
          :special-price="product.price.special"
          :link="product.link"
          link-tag="router-link"
          :product-quantity="product.quantityText"
          :wishlist-id="activeWishlistIndex"
          class="m-category-list__item o-cart-wishlist-content__product"
        />
      </div>
      <div
        v-else
        class="o-cart-wishlist-content__product-list--empty"
        :class="[
          {'o-cart-wishlist-content__product-list--is-cart': isCartType}
        ]"
      >
        <div class="empty-product-list__placeholder">
          <svg width="164" height="164" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="82" cy="82" r="82" fill="#F3F4F6" />
            <circle cx="82" cy="82" r="48" fill="#fff" />
            <path d="M90 68.7a9.5 9.5 0 0 0-7.7 3.5c-.2.2-.5.2-.6 0-.8-1-3.2-3.5-7.7-3.5-4.7 0-8.7 4-8.7 8.7 0 10.3 15 18.7 16.6 19.5h.2c1.6-.8 16.6-9.2 16.6-19.5 0-4.7-4-8.7-8.7-8.7Z" fill="#333" />
          </svg>
        </div>
        <div
          v-if="activeWishlist"
          class="empty-product-list__title"
        >
          {{ $t("You haven't put anything on the {listName} list yet", { listName: activeWishlist.name}) }}
        </div>
        <div class="empty-product-list__message">
          {{ $t('Items from favorites can be grouped into lists and quickly filled with them in the basket') }}
        </div>
      </div>
    </div>
    <ALoadingSpinner
      class="o-cart-wishlist__loader"
      :is-absolute-position="true" v-show="isShowSpinner"
    />
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue'
import MCartWishlistProductTile from 'theme/components/molecules/m-cart-wishlist-product-tile'
import { Wishlist } from 'theme/store/wishlist/components/Wishlist'
import { mapActions, mapGetters } from 'vuex'
import { notifications } from '@vue-storefront/core/modules/cart/helpers'
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner'
import { prepareCategoryProduct } from 'theme/helpers'
import { ModalList } from 'theme/store/ui/modals'
import DeviceType from 'theme/mixins/DeviceType'
import Vue from 'vue'
import AMyAccountWishlistCreateGroup from 'theme/components/atoms/a-my-account-wishlist-create-group';
import AMyAccountWishlistEditGroup from 'theme/components/atoms/a-my-account-wishlist-edit-group';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { attributesCodes } from 'config';

export default {
  name: 'OCartWishlist',
  components: {
    MCartWishlistProductTile,
    SfButton,
    ALoadingSpinner
  },
  mixins: [
    Wishlist,
    DeviceType
  ],
  props: {
    isShowEditAction: {
      type: Boolean,
      default: false
    }
  },
  inject: {
    isCartType: {
      from: 'isCartType',
      default: false
    }
  },
  data () {
    return {
      activeWishlistIndex: 0,
      isShowSpinner: false
    }
  },
  computed: {
    ...mapGetters({
      getWishlists: 'wishlist/getWishlists',
      productsInCart: 'cart/getCartItems',
      allWishlistItems: 'wishlist/getWishlistItems'
    }),
    products () {
      const items = this.getActiveWishlistItems();

      if (!items.length) {
        return [];
      }

      return items.map(item => item.link ? item : prepareCategoryProduct(item));
    },
    activeWishlist () {
      const activeList = this.wishlists.find(stateGroup => String(stateGroup.wishlist_id) === String(this.activeWishlistIndex))
      return activeList || this.wishlists.find(item => item.wishlist_id === this.defaultWishlistId)
    },
    wishlists () {
      return this.getWishlists.map(wishlist => ({
        ...wishlist,
        name: wishlist.name === 'Default' ? this.getDefaultWishlistName() : wishlist.name
      }));
    },
    defaultWishlistId () {
      const defaultWishlist = this.getWishlists.find(list => list.name === 'Default')
      return defaultWishlist ? defaultWishlist.wishlist_id : null
    },
    locale () {
      return currentStoreView().i18n.defaultLocale;
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal'
    }),
    isWishlistActive (wishlist_id) {
      return this.activeWishlistIndex === wishlist_id
    },
    setActiveWishlist (wishlist_id) {
      this.activeWishlistIndex = wishlist_id
    },
    async addAllProductsToCart () {
      this.isShowSpinner = true;
      await this.processAddAllProductsToCart();
      this.isShowSpinner = false;
    },
    async processAddAllProductsToCart () {
      const productsToAdd = this.products.filter(product => {
        const indexInCart = this.productsInCart.findIndex(p => p.id === product.id);
        return indexInCart === -1 && this.getIsInStock(product);
      }).map(product => Object.assign({}, product, { qty: this.getQtyForProduct(product) }));

      try {
        const diffLog = await this.$store.dispatch('cart/addItems', { productsToAdd });

        diffLog.clientNotifications.forEach(notificationData => {
          this.$store.dispatch(
            'notification/spawnNotification',
            notificationData,
            { root: true }
          );
        });
      } catch (message) {
        await this.$store.dispatch(
          'notification/spawnNotification',
          notifications.createNotification({ type: 'danger', message }),
          { root: true }
        );
      }
    },
    editWishlistModal () {
      this.openModal({ name: ModalList.EditGroupWishlist, payload: this.activeWishlist })
    },
    mountActionToMobileBar () {
      if (typeof document === 'undefined') {
        return
      }

      const closePositionWishlist = document.getElementById('close-position-wishlist')

      if (!closePositionWishlist) {
        return
      }

      closePositionWishlist.innerHTML = ''

      const wrapper = document.createElement('div')
      wrapper.id = 'close-position-wrapper'
      closePositionWishlist.append(wrapper)

      const Component = Vue.extend(this.isWishlistActive(this.defaultWishlistId) ? AMyAccountWishlistCreateGroup : AMyAccountWishlistEditGroup)
      const componentInstance = new Component({
        store: this.$store,
        propsData: {
          activeGroup: this.activeWishlist || {}
        }
      })

      componentInstance.$mount(wrapper)
    },
    getIsInStock (product) {
      return product.stock && product.stock.is_in_stock && product.stock.qty > 0
    },
    getActiveWishlistItems () {
      const wishlist = this.activeWishlist || {}
      const allItems = this.allWishlistItems || []

      if (!wishlist?.items?.length || !allItems.length) {
        return []
      }

      return wishlist.items.map(item => allItems.find(p => p.id === item.product_id)).filter(Boolean)
    },
    getDefaultWishlistName () {
      if (this.locale === 'uk-UA') {
        return 'Обране'
      } else if (this.locale === 'ru-RU') {
        return 'Избранное'
      } else {
        return 'Favorites'
      }
    },
    getQtyForProduct (product) {
      if (product.packingtype) {
        const minSalableQty = this.getAttributeValue(product, 'minSalableQty')
        const qtyUnitStep = this.getAttributeValue(product, 'qtyUnitStep')

        return minSalableQty || qtyUnitStep || this.unitStep || 1;
      }
      return 1
    },
    getAttributeValue (product, code) {
      return product[code] ||
        product[attributesCodes[code]]
    }
  },
  watch: {
    activeWishlistIndex: function () {
      this.mountActionToMobileBar()
    },
    isMobile: function () {
      this.mountActionToMobileBar()
    },
    wishlists: {
      handler: function (wishlists) {
        if (this.activeWishlistIndex && !wishlists.some(wishlist => wishlist.wishlist_id === this.activeWishlistIndex)) {
          this.activeWishlistIndex = wishlists[0].wishlist_id
        }
      },
      deep: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.mountActionToMobileBar()
      this.setActiveWishlist(this.defaultWishlistId)
    })
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.o-cart-wishlist {
  &-header {
    overflow-x: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      height: 3px;
      width: 30px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(235, 103, 71, 0.3);

      &:hover {
        background-color: var(--orange)
      }
    }

    &__group {
      display: inline-block;
      font-family: var(--font-family-secondary);
      font-weight: var(--font-medium);
      font-size: var(--font-base);
      line-height: var(--font-size-19);
      color: var(--c-text-muted);
      margin: 0 var(--spacer-15);
      padding: 0 0 px2rem(15);
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &--active {
        color: var(--orange);
        position: relative;

        &::after {
          background: var(--orange);
          content: '';
          display: inline-block;
          height: 3px;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
  }

  &-content {
    padding: var(--spacer-20) 0 0;
    border-top: 1px solid var(--light-gray);
    position: relative;
    z-index: 1;

    &__product-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      &--empty {
        text-align: center;
        color: var(--dark-gray);
        padding-top: px2rem(12);
        padding-bottom: px2rem(12);

        @media (min-width: $tablet-min) {
          padding-top: px2rem(127);
          padding-bottom: px2rem(127);

          &.o-cart-wishlist-content__product-list--is-cart {
            padding-top: px2rem(75);
            padding-bottom: px2rem(75);
          }
        }
      }
    }

    &__product {
      border-bottom: 1px solid var(--gray-light);

      &:last-child {
        border-bottom: none;
      }

      @media (max-width: $mobile-max) {
        width: 50%;
      }
    }

    &__button-items-to-cart {
      width: 100%;
      --button-padding: var(--spacer-16) var(--spacer-35);

      @include for-tablet {
        width: auto;
      }

      @include for-desktop {
        width: auto;
      }
    }

    &__navbar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 10px;

      @media (min-width: $tablet-min) {
        min-height: px2rem(52);
      }

      @include for-tablet {
        flex-direction: row;
      }

      @include for-desktop {
        flex-direction: row;

        &-block--edit {
          padding: px2rem(15) 0;
        }
      }

      .action-edit-group {
        cursor: pointer;
        display: flex;
        align-items: center;

        &__icon {
          display: flex;
        }

        &__title {
          margin-left: px2rem(17);
          color: var(--orange);
          font-size: var(--font-size-14);
          font-weight: var(--font-normal);
        }
      }

      &--is-cart {
        .o-cart-wishlist-content__navbar-block {
          width: 100%;
        }
        .o-cart-wishlist-content__button-items-to-cart {
          width: 100%;
        }
        &.o-cart-wishlist-content__navbar--empty {
          display: none;
        }
      }
    }
  }
}
.empty-product-list {
  &__title {
    font-size: var(--font-size-20);
    font-weight: var(--font-medium);
    margin-top: var(--spacer-35);
    line-height: var(--font-size-20);
  }

  &__message {
    font-size: var(--font-size-14);
    font-weight: var(--font-normal);
    color: var(--dark-gray);
    margin-top: var(--spacer-15);
    line-height: var(--font-size-17);
  }
}
::v-deep {
  .o-cart-wishlist__loader {
    .sf-loader__overlay {
      align-items: flex-start;
      padding-top: px2rem(325);
    }
  }
}
</style>
