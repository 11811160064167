<template>
  <div
    class="a-my-account-wishlist-edit-group"
    data-transaction-name="Account - Wishlist - Edit Group"
    @click="openModalWishlistEditGroup"
  >
    <svg
      width="30"
      height="30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="5" fill="#F3F4F6" />
      <path d="M17 10 7 20v3h3l10-10m-3-3 3-3 3 3-3 3m-3-3 3 3" stroke="#333" />
    </svg>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { ModalList } from 'theme/store/ui/modals'

export default {
  name: 'AMyAccountWishlistEditGroup',
  props: {
    activeGroup: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    ...mapActions('ui', {
      openModal: 'openModal'
    }),
    openModalWishlistEditGroup () {
      this.openModal({ name: ModalList.EditGroupWishlist, payload: this.activeGroup })
    }
  }
}
</script>

<style lang="scss" scoped>
.a-my-account-wishlist-edit-group {
  display: flex;
}
</style>
