<template>
  <SfButton
    class="sf-button--pure"
    @click="onClickHandler"
  >
    <span class="icon-delete" />
  </SfButton>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import { mapActions, mapGetters } from 'vuex';
import { getWishlistProductId } from 'theme/store/wishlist/helpers'
import { Logger } from '@vue-storefront/core/lib/logger';

export default {
  name: 'ADeleteFromWishlist',
  components: { SfButton },
  props: {
    product: {
      required: true,
      type: Object
    },
    wishlistId: {
      required: true,
      type: Number
    }
  },
  methods: {
    ...mapActions({
      deleteItemsFromWishlist: 'wishlist/deleteItemsFromWishlist',
      collectItemsFromWishlists: 'wishlist/collectItemsFromWishlists'
    }),
    async deleteFromWishlist () {
      const { wishlistId, product, getWishlists } = this;
      if (!wishlistId || !product) return;

      try {
        await this.deleteItemsFromWishlist({
          wishlistId: Number(wishlistId),
          items: [getWishlistProductId(product, wishlistId, getWishlists)]
        });
        await this.collectItemsFromWishlists();
      } catch (error) {
        Logger.warn(error, 'a-delete-from-wishlist/deleteItemsFromWishlist');
      }
    },
    onClickHandler () {
      this.deleteFromWishlist();
    }
  },
  computed: {
    ...mapGetters({
      getWishlists: 'wishlist/getWishlists'
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/fonts';
.icon-delete {
  @include font-icon(var(--icon-trash));

  &:before {
    font-size: var(--font-size-18);
    color: var(--dark-gray);
    transition: color .3s ease;
  }
}
</style>
