const getWishlistById = (wishlists, wishlistId) => {
  return wishlists.find(wishlist => wishlist.wishlist_id === Number(wishlistId));
}
const getWishlistItems = (wishlist) => {
  return wishlist.items;
}
const getWishlistItemId = (wishlistItems, product) => {
  return wishlistItems.find(item => item.product_id === product.id).wishlist_item_id;
}

const getWishlistProductId = (product, wishlistId, wishlists) => {
  const wishlist = getWishlistById(wishlists, wishlistId);
  const wishlistItems = getWishlistItems(wishlist);
  return getWishlistItemId(wishlistItems, product);
}

export { getWishlistProductId };
