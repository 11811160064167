<template>
  <div
    class="a-my-account-wishlist-create-group"
    data-transaction-name="Account - Wishlist - Create Group"
    @click="openModalCreateNewWishlist"
  >
    <svg
      width="30"
      height="30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="5" fill="#F3F4F6" />
      <path d="M15.5 8v7.5m0 0H8m7.5 0V23m0-7.5H23" stroke="#333" />
    </svg>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ModalList } from 'theme/store/ui/modals'

export default {
  name: 'AMyAccountWishlistCreateGroup',
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      setModeCreateNewGroup: 'wishlist/setModeCreateNewGroup'
    }),
    async openModalCreateNewWishlist () {
      await this.setModeCreateNewGroup(true)
      this.openModal({ name: ModalList.CreateWishlist })
    }
  }
}
</script>

<style lang="scss" scoped>
.a-my-account-wishlist-create-group {
  display: flex;
}
</style>
